import { CSSProperties } from 'react';

import { Colors, ColorName } from '@kvika/audur-theme';
import { DisruptionTypeEnum, EnquiryType } from '@kvika/api-types';

export enum ImageObjectFit {
  Fill = 'fill',
  Contain = 'contain',
  Cover = 'cover',
  ScaleDown = 'scale-down',
  None = 'none',
}

export type MetaTagInfo = {
  title: string;
  description: string;
  imageUrl: string;
};

export enum ImageLayout {
  Fixed = 'fixed',
  Intrinsic = 'intrinsic',
  Responsive = 'responsive',
}

export type ImageOffset = {
  x: number;
  y: number;
};

export type ImageSource = {
  src: string;
  alt: string;
  width: number;
  height: number;
  objectFit?: ImageObjectFit;
  objectPosition?: string;
  imageOffset?: ImageOffset;
  layout?: ImageLayout;
};

export type LinkSource = {
  title: string;
  href: string;
  fillColor?: Colors;
  style?: CSSProperties;
  openInNewTab?: boolean;
};

export type HtmlHeading = {
  html: string;
  headingClass?: string;
  inlineColorClass?: ColorName;
};

export enum GridArea {
  gutter = '.',
  content = 'content',
  all = 'all',
  fullWidth = '1 / span 3',
}

export enum GridColumn {
  All = '1 / 13',
  Left = '1 / 7',
  Right = '7 / 13',
  Auto = 'auto',
}

export enum ColumnClass {
  All = 'grid-columns-all',
  TwoThirds = 'grid-columns-two-thirds',
  Left = 'grid-columns-left',
  Right = 'grid-columns-right',
  Auto = 'grid-columns-auto',
  Left50 = 'grid-columns-left-50',
  Right50 = 'grid-columns-right-50',
  Left25 = 'grid-columns-left-25',
  Right25 = 'grid-columns-right-25',
  Left75 = 'grid-columns-left-75',
  Right75 = 'grid-columns-right-75',
}

export type GridPosition = {
  gridColumnStart?: number;
  gridColumnEnd?: number;
  gridColumn?: GridColumn;
};

export enum Gutters {
  Both,
  LeftOnly,
  None,
  RightOnly,
}

export type TableRow = {
  tableData: Array<string>;
};

export enum CalculatorTab {
  SavingsCalculator,
  TermCalculator,
  FutureCalculator,
  CompanyCalculator,
}

export enum AudurCookies {
  CookiePolicyClosed = 'cookiepolicyclosed',
  StatisticsCookieApproved = 'statisticcookieapproved',
  MarketingCookieApproved = 'marketingcookieapproved',
}

export enum EditorialContentType {
  Inline = 'INLINE',
  PageSection = 'PAGE_SECTION',
  Header = 'HEADER',
}

export enum ContentWidth {
  _25 = '25',
  _50 = '50',
  _75 = '75',
  _100 = '100',
}

export enum Alignment {
  Left = 'LEFT',
  Right = 'RIGHT',
}

export enum LinkStyle {
  Regular = 'REGULAR',
  Button = 'BUTTON',
  DarkButton = 'DARK_BUTTON',
}

export enum PageLayout {
  Standard = 'STANDARD',
  AppLayout = 'APP_LAYOUT',
}

export enum BannerTab {
  FirstBanner,
  SecondBanner,
  ThirdBanner,
}

export type PreviewData = {
  ref: string;
};

export type ServiceStatusMode = {
  serviceStatus?: DisruptionTypeEnum;
  message: string;
};

export type ContactOption = {
  value: EnquiryType;
  label: string;
};
